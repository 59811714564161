<template>
  <div
    class="overflow-y-auto skif-event-form absolute bottom-5 top-5 left-5 z-30 bg-white shadow pr-4 pl-6 pt-5 w-module"
  >
    <div class="flex justify-between items-center">
      <div
        class="text-darkblue text-xl font-bold whitespace-no-wrap font-SourceSansPro"
      >
        {{ title === 1 ? $t('events.create') : $t('events.update') }}
      </div>
      <i
        class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
        @click="$emit('close')"
      />
    </div>
    <div class="skif-form__row --flex">
      <el-select
        type="text"
        v-model="currentType"
        filterable="filterable"
        :no-match-text="$t('noMatch')"
        :placeholder="$t('reports.not-select')"
      >
        <el-option
          v-for="item in types"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <skif-button width="28" @click="submitForm" :disabled="loading">
        {{ title === 1 ? $t('create') : $t('change') }}
      </skif-button>
    </div>
    <el-form :model="data" :rules="rules" :disabled="loading" ref="eventForm">
      <div class="skif-form__row" v-if="needShowField('datetime')">
        <div class="skif-form__label">
          <span class="skif-form__star" v-if="isRequiredField('datetime')">
            *
          </span>
          {{ getLabel('datetime') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="datetime">
            <el-date-picker
              type="datetime"
              v-model="data.datetime"
              :key="currentType + key"
              :clearable="false"
              :format="dateTimeFormat"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('start_date')">
        <div class="skif-form__label">
          <span class="skif-form__star" v-if="isRequiredField('start_date')">
            *
          </span>
          {{ getLabel('start_date') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="start_date">
            <el-date-picker
              v-model="data.start_date"
              :key="currentType + key"
              type="datetime"
              :clearable="false"
              :format="dateTimeFormat"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('finish_date')">
        <div class="skif-form__label">
          <span class="skif-form__star" v-if="isRequiredField('finish_date')">
            *
          </span>
          {{ getLabel('finish_date') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="finish_date">
            <el-date-picker
              :key="currentType + key"
              type="datetime"
              :clearable="false"
              v-model="data.finish_date"
              :format="dateTimeFormat"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('user_id')">
        <div class="skif-form__label">
          <span class="skif-form__star" v-if="isRequiredField('user_id')">
            *
          </span>
          {{ getLabel('user_id') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="user_id">
            <el-select
              :key="currentType + key"
              v-model="data.user_id"
              filterable="filterable"
              :no-match-text="$t('noMatch')"
              :placeholder="$t('reports.not-select')"
            >
              <el-option
                v-for="item in drivers"
                :key="item.id"
                :label="item.name ? `${item.name}(${item.email})` : item.email"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('deviation_minutes')">
        <div class="skif-form__label">
          <span
            class="skif-form__star"
            v-if="isRequiredField('deviation_minutes')"
          >
            *
          </span>
          {{ getLabel('deviation_minutes') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="deviation_minutes">
            <el-input-number
              class="w-1/3"
              :key="currentType + key"
              v-model="data.deviation_minutes"
              :min="0"
              :max="1000"
              :controls="false"
            />
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('filled')">
        <div class="skif-form__label">
          <span class="skif-form__star" v-if="isRequiredField('filled')">
            *
          </span>
          {{ getLabel('filled') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="filled">
            <el-input-number
              class="w-1/3"
              :key="currentType + key"
              v-model="data.filled"
              :min="0"
              :controls="false"
            />
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('consumed')">
        <div class="skif-form__label">
          <span class="skif-form__star" v-if="isRequiredField('consumed')">
            *
          </span>
          {{ getLabel('consumed') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="consumed">
            <el-input-number
              class="w-1/3"
              :key="currentType + key"
              v-model="data.consumed"
              :min="0"
              :controls="false"
            />
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('stolen')">
        <div class="skif-form__label">
          <span class="skif-form__star" v-if="isRequiredField('stolen')">
            *
          </span>
          {{ getLabel('stolen') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="stolen">
            <el-input-number
              class="w-1/3"
              :key="currentType + key"
              v-model="data.stolen"
              :min="0"
              :controls="false"
            />
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('service_name')">
        <div class="skif-form__label">
          <span class="skif-form__star" v-if="isRequiredField('service_name')">
            *
          </span>
          {{ getLabel('service_name') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="service_name">
            <el-select
              :key="currentType + key"
              v-model="data.service_name"
              filterable="filterable"
              allow-create="allow-create"
              default-first-option="default-first-option"
              :no-match-text="$t('noMatch')"
              :placeholder="$t('reports.not-select')"
            >
              <el-option
                v-for="(item, i) in servicesUnical"
                :key="i"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('motohours')">
        <div class="skif-form__label">
          <span class="skif-form__star" v-if="isRequiredField('motohours')">
            *
          </span>
          {{ getLabel('motohours') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="motohours">
            <el-input-number
              class="w-1/3"
              :key="currentType + key"
              v-model="data.motohours"
              :min="0"
              :controls="false"
            />
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('millage')">
        <div class="skif-form__label">
          <span class="skif-form__star" v-if="isRequiredField('millage')">
            *
          </span>
          {{ getLabel('millage') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="millage">
            <el-input-number
              class="w-1/3"
              :key="currentType + key"
              v-model="data.millage"
              :min="0"
              :controls="false"
            />
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('duration_hours')">
        <div class="skif-form__label">
          <span
            class="skif-form__star"
            v-if="isRequiredField('duration_hours')"
          >
            *
          </span>
          {{ getLabel('duration_hours') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="duration_hours">
            <el-input-number
              class="w-1/3"
              :key="currentType + key"
              v-model="data.duration_hours"
              :min="0"
              :controls="false"
            />
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('price')">
        <div class="skif-form__label">
          <span class="skif-form__star" v-if="isRequiredField('price')">
            *
          </span>
          {{ getLabel('price') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="price">
            <el-input-number
              class="w-1/3"
              :key="currentType + key"
              v-model="data.price"
              :min="0"
              :controls="false"
            />
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('guaranteed')">
        <div class="skif-form__field">
          <el-form-item prop="guaranteed">
            <el-checkbox v-model="data.guaranteed" :key="currentType + key">
              {{ $t('events.guaranteed') }}
            </el-checkbox>
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('type_name')">
        <div class="skif-form__label">
          <span class="skif-form__star" v-if="isRequiredField('type_name')">
            *
          </span>
          {{ getLabel('type_name') }}
        </div>
        <template v-if="currentType === 'random'">
          <div class="skif-form__field">
            <el-form-item prop="type_name">
              <el-select
                :key="currentType + key"
                v-model="data.type_name"
                filterable="filterable"
                allow-create="allow-create"
                default-first-option="default-first-option"
                name="selectTypeName"
                :no-match-text="$t('noMatch')"
                :placeholder="$t('reports.not-select')"
              >
                <el-option
                  v-for="(item, i) in eventsRandomNameTypes"
                  :key="i"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </div>
        </template>
        <template v-if="currentType === 'vehicle_status'">
          <div class="skif-form__field">
            <el-form-item prop="type_name">
              <el-select
                :key="currentType + key"
                v-model="data.type_name"
                filterable
                :allow-create="isHighRole"
                default-first-option
                name="selectTypeName"
                :no-match-text="$t('noMatch')"
                :placeholder="$t('reports.not-select')"
              >
                <el-option
                  v-for="item in eventVehicleStatusTypes"
                  :key="item.id"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </div>
        </template>
      </div>
      <div class="skif-form__row" v-if="needShowField('duration_minutes')">
        <div class="skif-form__label">
          <span class="skif-form__star">*</span>
          {{ getLabel('duration_minutes') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="duration_minutes">
            <el-input-number
              v-model="data.duration_minutes"
              class="w-1/3"
              :key="currentType + key"
              type="number"
              :min="0"
              :max="59"
              :controls="false"
            />
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('random_duration_hours')">
        <div class="skif-form__label">
          <span class="skif-form__star">*</span>
          {{ getLabel('duration_hours') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="random_duration_hours">
            <el-input-number
              class="w-1/3"
              :key="currentType + key"
              v-model="data.random_duration_hours"
              :min="0"
              :controls="false"
            />
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row" v-if="needShowField('description')">
        <div class="skif-form__label">
          <span class="skif-form__star" v-if="isRequiredField('description')"
            >*</span
          >
          {{ getLabel('description') }}
        </div>
        <div class="skif-form__field">
          <el-form-item prop="description">
            <el-input
              :key="currentType + key"
              v-model="data.description"
              type="textarea"
              maxlength="2000"
              rows="4"
            />
          </el-form-item>
        </div>
      </div>
      <div class="skif-form__row">
        <div class="skif-form__info-text">
          <info-icon class="skif-form__icon mr-2" />
          <div class="skif-form__star">*</div>
          - {{ $t('events.requiredFields') }}
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { trackingApi } from '@/api'
import websocketHandler from '@/mixins/websocketHandler.js'
import { typesFields, typesRequiredFields } from '@/utils/events.js'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    infoIcon: () => import('@/assets/svg-icons/info.svg')
  },
  mixins: [websocketHandler],
  props: {
    unit: Object,
    title: Number,
    eventToUpdate: Object
  },
  data() {
    const checkRequired = (rule, value, callback) => {
      if (!value && this.isRequiredField(rule.field)) {
        callback(new Error(this.$t('events.validation.required')))
      } else {
        callback()
      }
    }

    const checkNumber = (rule, value, callback) => {
      if (!value && this.isRequiredField(rule.field)) {
        callback(new Error(this.$t('events.validation.number')))
      } else {
        callback()
      }
    }

    return {
      isRandom: false,
      eventsRandomNameTypes: [],
      eventVehicleStatusTypes: [],
      key: 0,
      timeOut: null,
      loading: false,
      currentType: 'filling', // filling, theft, accident, service, waybill, random
      // date time format
      pickerOptions: {
        firstDayOfWeek: 1
      },
      dateFormat: '',
      timeFormat: '',
      dateTimeFormat: '',
      submitDateTimeFormat: 'yyyy-MM-DD HH:mm:ss',
      data: {
        duration_minutes: 0,
        datetime: '',
        deviation_minutes: '',
        filled: '',
        consumed: '',
        price: '',
        description: '22',
        stolen: '',
        service_name: '',
        motohours: '',
        millage: '',
        duration_hours: '',
        random_duration_hours: '',
        user_id: '',
        type_name: '',
        start_date: '',
        finish_date: '',
        guaranteed: false,
        // coords
        latitude: '',
        longitude: ''
      },
      rules: {
        datetime: [
          {
            type: 'date',
            required: true,
            message: this.$t('events.validation.datetime'),
            trigger: 'change'
          }
        ],
        start_date: [
          {
            type: 'date',
            required: true,
            message: this.$t('events.validation.datetime'),
            trigger: 'change'
          }
        ],
        finish_date: [
          {
            type: 'date',
            required: true,
            message: this.$t('events.validation.datetime'),
            trigger: 'change'
          }
        ],
        deviation_minutes: [{ validator: checkNumber, trigger: 'change' }],
        filled: [{ validator: checkNumber, trigger: 'change' }],
        consumed: [{ validator: checkNumber, trigger: 'change' }],
        stolen: [{ validator: checkNumber, trigger: 'change' }],
        service_name: [{ validator: checkRequired, trigger: 'change' }],
        type_name: [{ validator: checkRequired, trigger: 'change' }]
      }
    }
  },
  computed: {
    ...mapState('dictionary', {
      types: (state) => state.event_type
    }),
    ...mapGetters('users', ['drivers']),
    ...mapState('login', ['me']),
    ...mapState('events', {
      randomTypes: (state) => state.events,
      lastPoints: (state) => state.lastPoints,
      nameTypes: (state) => state.nameTypes
    }),

    isHighRole() {
      return !!(
        this.me.role.key === 'CONTROLLER' ||
        this.me.role.key === 'TARIFF_EDITOR' ||
        this.me.role.key === 'SUPERVISOR' ||
        this.me.role.key === 'ADMIN'
      )
    },

    services() {
      return this.unit.services || []
    },
    currentFields() {
      const type = this.currentType
      return type && typesFields[type] ? typesFields[type] : []
    },
    requiredFields() {
      const type = this.currentType
      return type && typesRequiredFields[type] ? typesRequiredFields[type] : []
    },
    formEl() {
      return this.$refs.eventForm
    },
    randomTypesUnical() {
      const res = []
      const items = this.randomTypes || []
      items.forEach((item) => {
        const val = item.type_name
        if (val && !res.includes(val)) {
          res.push(val)
        }
      })
      return res
    },
    servicesUnical() {
      let defaultsItems = [
        'Осмотр',
        'Замена терминала',
        'Установка ДУТ',
        'Замена ДУТ',
        'Калибровка ДУТ'
      ]
      const items = this.services || []
      const lang = this.$i18n.locale()
      if (lang === 'en') {
        defaultsItems = [
          'Inspection',
          'Terminal replacement',
          'FLS installation',
          'FLS replacement',
          'FLS calibration'
        ]
      } else if (lang === 'kz') {
        defaultsItems = [
          'Тексеру',
          'Терминалды ауыстыру',
          'FLS орнату',
          'FLS ауыстыру',
          'FLS калибрлеу'
        ]
      }
      const res = defaultsItems
      items.forEach((item) => {
        const val = item.name
        if (val && !res.includes(val)) {
          res.push(val)
        }
      })
      return res
    }
  },
  watch: {
    currentType(val) {
      this.resetForm()
      this.setDefaults()
      if (val === 'random') {
        this.getRandomTypes()
        this.isRandom = true
      }
      if (val === 'vehicle_status') {
        this.getStatusTCTypes()
        this.$nextTick(() => {
          const selectTypeName = document.querySelector(
            '[name="selectTypeName"]'
          )
          selectTypeName.maxLength = 20
        })
      }
    },
    'data.datetime': function (val) {
      if (!val) return
      clearTimeout(this.timeOut)

      this.timeOut = setTimeout(() => {
        this.setCoords(val)
      }, 1500)
    }
  },
  methods: {
    setCoordData(coords) {
      if (coords && coords[0] && coords[1]) {
        this.data.latitude = coords[0]
        this.data.longitude = coords[1]
      }
    },
    async setCoords(date) {
      try {
        const from = moment(new Date(date)).format(this.submitDateTimeFormat)

        const formData = {
          units: [{ id: this.unit.id }],
          from
        }
        const res = await trackingApi.postPoint({ formData })
        const coords = res.data.columns.find(
          (item) => item.key === '_marker_latlon'
        ).value
        this.setCoordData(coords)
      } catch (error) {
        this.setCoordsFromWS()
      }
    },
    setCoordsFromWS() {
      const unitId = this.unit.id
      const coords =
        this.lastPoints && this.lastPoints[unitId]
          ? this.lastPoints[unitId]
          : null
      this.setCoordData(coords)
    },
    getLabel(field) {
      return this.$t(`events.${field}`)
    },
    needShowField(name) {
      return name && this.currentFields.includes(name)
    },
    isRequiredField(name) {
      return name && this.requiredFields.includes(name)
    },
    submitForm() {
      this.formEl.validate((valid) => {
        if (valid) {
          this.createOrUpdate()
        } else {
          return false
        }
      })
    },
    createSubmitData() {
      const cleanEmpty = {}
      Object.keys(this.data).forEach((key) => {
        if (
          this.data[key] ||
          (key === 'guaranteed' && this.currentType === 'service')
        ) {
          cleanEmpty[key] = this.data[key]
        }
      })

      const data = {
        unit: { id: this.unit.id },
        type: { key: this.currentType },
        ...cleanEmpty
      }

      const dateField = ['datetime', 'start_date', 'finish_date']
      dateField.forEach((key) => {
        if (data[key]) {
          data[key] = moment(new Date(data[key])).format(
            this.submitDateTimeFormat
          )
        }
      })
      return data
    },
    async createOrUpdate() {
      const data = this.createSubmitData()

      this.loading = true
      try {
        if (this.title === 1) {
          await this.$store.dispatch('events/CREATE', data)
        } else {
          await this.$store.dispatch('events/UPDATE', {
            ...data,
            id: this.eventToUpdate.id
          })
        }
        this.$notify.success({
          message: this.$t('notification_alert.success'),
          position: 'top-right'
        })
        this.$emit('close')
      } catch (error) {
        console.error('error:', error)
      }
      this.loading = false
    },
    resetForm() {
      this.formEl.resetFields()
    },
    clearValidate() {
      this.formEl.clearValidate()
    },
    setDefaults() {
      const data = {}

      Object.keys(this.data).forEach((key) => {
        if (key === 'guaranteed') {
          data[key] = false
        } else {
          data[key] = ''
        }
      })

      if (this.currentType === 'waybill') {
        data.start_date = new Date()
      } else {
        data.datetime = new Date()
      }

      if (this.currentType === 'service') {
        data.guaranteed = true
        data.service_name =
          this.servicesUnical && this.servicesUnical.length
            ? this.servicesUnical[0]
            : ''
      }
      this.data = Object.assign(this.data, data)
    },
    async getRandomTypes() {
      const data = {
        from: 0,
        count: 100,
        fields: ['type_name'],
        conditions: [
          // {
          //   field: 'unit.id',
          //   value: this.unit.id,
          // },
          {
            field: 'type.key',
            value: 'random'
          }
        ]
      }
      await this.$store.dispatch('events/GET_TYPENAMES', data)
      this.$nextTick(() => {
        this.nameTypes.forEach((name) =>
          this.eventsRandomNameTypes.indexOf(name.type_name) === -1
            ? this.eventsRandomNameTypes.push(name.type_name)
            : ''
        )
      })
    },

    async getStatusTCTypes() {
      const formData = {
        from: 0,
        count: 100,
        fields: ['type_name'],
        conditions: [
          {
            field: 'type.key',
            value: 'vehicle_status'
          }
        ]
      }
      await this.$store.dispatch('events/GET_TYPENAMES', formData)
      this.$nextTick(() => {
        this.nameTypes.forEach((name) =>
          this.eventVehicleStatusTypes.indexOf(name.type_name) === -1
            ? this.eventVehicleStatusTypes.push(name.type_name)
            : ''
        )
      })
    }
  },
  async created() {
    if (this.title === 1) {
      this.setDefaults()
    }
    await this.$store.dispatch('dictionary/getDictionaryByType', 'event_type')
    this.dateFormat = this.me.active_company.dateformat.key
    this.timeFormat = this.me.active_company.timeformat.key
    this.dateTimeFormat = `${this.dateFormat} ${this.timeFormat}`
    if (this.title === 2) {
      const event = this.eventToUpdate
      this.currentType = event.type.key
      this.data = Object.assign(this.data, event)
      if (this.data.datetime) {
        this.data.datetime = new Date(event.datetime)
      }
      if (this.data.start_date) {
        this.data.start_date = new Date(event.start_date)
      }
      if (this.data.finish_date) {
        this.data.finish_date = new Date(event.finish_date)
      }
    }
  },
  mounted() {
    if (this.title === 2) {
      const event = this.eventToUpdate
      this.currentType = event.type.key
    }
  }
}
</script>

<style lang="stylus">
[class*=" el-icon-"]
  vertical-align middle
  height auto
.skif-event-form {
  .el-date-editor {
    width: 13rem;
  }
}

.skif-form {
  &__row {
    margin: 20px 0;

    &.--flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__label {
    display: block;
    margin: 0 0 15px 0;
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #5477A9;
  }

  &__icon {
    width: 1em;
    height: 1em;
    vertical-align: baseline;
  }

  &__info-text {
    margin: 30px 0 0 0;
    color: #5477A9;
    font-size: 14px;
    line-height: 1;
  }

  &__star {
    color: #EB5757;
    display: inline-block;
    margin-right: 0.3em;
  }
}
</style>
